import React, { useContext } from "react"
import { Box, Flex } from "@theme-ui/components"
import { InboundLink, OutboundLink } from "./link"
import {
  getArticleCategoryPath,
  getBlogPath,
  getHomePath,
  getCategoryPath,
  getChannelPath,
  getPagePath,
  getEventPath,
} from "../utils/path"
import { ChannelMenuFooter } from "../context/channelMenuFooter"

const Breadcrumbs = ({ page, videoCategory = undefined }) => {
  const menuType = useContext(ChannelMenuFooter).slug

  // console.log(page)
  function renderSwitch(page) {
    switch (page.model.apiKey) {
      case "video":
        return (
          <VideoBreadcrumbs
            page={page}
            menuType={menuType}
            pageCategory={
              videoCategory ? videoCategory : page.category && page.category
            }
          />
        )
      case "video_category":
        return <CategoryBreadcrumbs menuType={menuType} page={page} />
      case "latest_videos":
        return <LatestVideosBreadcrumbs menuType={menuType} page={page} />
      case "article":
        return <ArticleBreadcrumbs menuType={menuType} page={page} />
      case "reportage":
        return <ReportageBreadcrumbs menuType={menuType} page={page} />
      case "event":
        return <EventBreadcrumbs menuType={menuType} page={page} />
      default:
        return <PageBreadcrumbs menuType={menuType} page={page} />
    }
  }

  const VideoBreadcrumbs = ({ page, pageCategory, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="primary"
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      {pageCategory && (
        <Item color="primary">
          <InboundLink
            color="primary"
            to={getCategoryPath(pageCategory, pageCategory.locale)}
          >
            {pageCategory.title}
          </InboundLink>
        </Item>
      )}
    </List>
  )

  const CategoryBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="primary"
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      {page.treeParent && page.treeParent.treeParent && (
        <Item>
          <InboundLink
            color="dark"
            sx={{
              textDecoration: "underline",
            }}
            to={getCategoryPath(page.treeParent.treeParent)}
          >
            {page.treeParent.treeParent.title}
          </InboundLink>
        </Item>
      )}
      {page.treeParent && (
        <Item>
          <InboundLink
            color="dark"
            sx={{
              textDecoration: "underline",
            }}
            to={getCategoryPath(page.treeParent)}
          >
            {page.treeParent.title}
          </InboundLink>
        </Item>
      )}
      <Item
        color="dark"
        style={{
          textDecoration: "underline",
        }}
      >
        {page.title}
      </Item>
    </List>
  )

  const LatestVideosBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="primary"
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      <Item
        color="dark"
        style={{
          textDecoration: "underline",
        }}
      >
        {page.title}
      </Item>
    </List>
  )

  const PageBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="dark"
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      {page.treeParent && page.treeParent.treeParent && (
        <Item>
          <InboundLink
            color="dark"
            sx={{
              textDecoration: "underline",
            }}
            to={getPagePath(page.treeParent.treeParent)}
          >
            {page.treeParent.treeParent.title ||
              page.treeParent.treeParent.name}
          </InboundLink>
        </Item>
      )}
      <Item
        color="dark"
        style={{
          textDecoration: "underline",
        }}
      >
        {page.title}
      </Item>
    </List>
  )

  const ArticleBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      <Item>
        <InboundLink to={getBlogPath(page.channel, page.locale)}>
          Blog
        </InboundLink>
      </Item>
      {page.category && (
        <Item>
          <InboundLink to={getArticleCategoryPath(page.category, page.locale)}>
            {page.category.title}
          </InboundLink>
        </Item>
      )}
    </List>
  )

  const ReportageBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="primary"
          to={
            menuType === "home"
              ? getHomePath(page.locale)
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      {page.channel && (
        <Item>
          <InboundLink
            color="primary"
            to={getChannelPath(page.channel.pageLink.slug, page.locale)}
          >
            {page.channel.pageLink.title}
          </InboundLink>
        </Item>
      )}
    </List>
  )

  const EventBreadcrumbs = ({ page, menuType }) => (
    <List>
      <Item>
        <InboundLink
          color="dark"
          to={
            menuType === "home"
              ? getHomePath("en")
              : getChannelPath(menuType, page.locale)
          }
        >
          Home
        </InboundLink>
      </Item>
      <Item
        color="dark"
        style={{
          textDecoration: "underline",
        }}
      >
        {page.title}
      </Item>
    </List>
  )

  return renderSwitch(page)
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: ["column", "column", "row"],
        margin: 0,
        padding: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box
      {...props}
      sx={{
        textTransform: "lowercase",
        marginRight: 2,
        "&::after": {
          content: '">"',
          color: "primary",
          marginLeft: 2,
          display: ["inline"],
        },
        "&:last-child": {
          marginRight: 0,
          "&::after": {
            display: "none",
          },
        },
      }}
      as="li"
    />
  )
}

export default Breadcrumbs
